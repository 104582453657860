




















import { Modal } from '@/app_code/Modals/Modal'
import { InvoiceModel } from 'truemarket-modules/src/models/api/invoices'
import { GetReportModel } from 'truemarket-modules/src/models/api/reports'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class InvoiceDetails extends Vue {
  public invoice: InvoiceModel | null = null
  public invoiceUrl: string | null = null
  public reports: GetReportModel[] | null = null

  async mounted () {
    const invoiceId = this.$route.params.id as string

    this.invoice = await Services.API.Payment.GetInvoice(invoiceId)

    const invoiceHtml = await Services.API.Payment.GetInvoiceHTML(invoiceId)

    this.invoiceUrl = URL.createObjectURL(invoiceHtml)

    const valuationId = this.$route.query.ValuationId

    if (valuationId != null) {
      Services.API.Valuations.GetReports(valuationId.toString()).then((reports) => {
        this.reports = reports
      })
    }
  }

  getReportUrl (reportId: string) {
    return `${window.location.origin}/admin/reports/print/${reportId}?iframe=true`
  }

  beforeDestroy () {
    if (this.invoiceUrl) URL.revokeObjectURL(this.invoiceUrl)
  }

  async downloadInvoice (): Promise<void> {
    if (!this.invoice) return

    const modal = new Modal({ Heading: 'Please Wait...', HtmlBody: 'Generating Invoice PDF...' })
    modal.Show()

    const pdfBlob = await Services.API.Payment.GetInvoicePDF(this.invoice.InvoiceId)

    const pdfUrl = URL.createObjectURL(pdfBlob)

    modal.Hide()

    window.open(pdfUrl, '_blank')

    setTimeout(() => {
      URL.revokeObjectURL(pdfUrl)
    }, 5000)
  }

  printIframe (reportId: string) {
    const iframe = document.getElementById(`iframe-${reportId}`) as HTMLIFrameElement
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.focus() // Focus the iframe
      iframe.contentWindow.print() // Trigger print
    } else {

    }
  }
}
